import { Currency } from '@altermeliora/payform-types';
import { join } from 'path';
import i18n from '../../i18n';
import { CartItem } from '../common-interfaces';
import { defaultLanguage } from '../constants/locales';
import { PayformCurrency } from '../../graphql-types';
import GQL from '../../graphql-types';

export const encodeQueryData = (data: any) => {
  const ret = [];
  for (const d in data) {
    if (data[d] != null && data[d] !== '') {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }
  }
  return ret.join('&');
};

export const itemDiscountTotal = (item: CartItem, currency: PayformCurrency): number => {
  if (item.discount) {
    const foundProduct = item.discount.products.find(product => String(product.id) === item.product.id);
    const foundTotal =
      foundProduct &&
      foundProduct.totals.find(total => {
        return String(total.currency) === String(currency);
      });
    return foundTotal ? foundTotal.total / item.quantity : 0;
  }
  return 0;
};

export const toDecimals = (number: number, decimals = 2): number => {
  const power = 10 ** decimals;
  return Math.round(number * power) / power;
};

export const pathTo = (url: string): string => {
  const langPathPrefix: string = i18n.language === defaultLanguage ? '' : i18n.language;
  return join('/', langPathPrefix, url);
};

export enum CurrencySymbols {
  'euro' = '\u20AC',
  'dollar' = '\u0024',
  'ruble' = '\u20BD',
  'shekel' = '\u20AA',
}

export const fixedNum = (num: number, counter: number = 2) => {
  const f = (x: number) => (~(x + '').indexOf('.') ? (x + '').split('.')[1].length : 0);
  if (num) {
    if (f(num)) {
      return num.toFixed(counter);
    } else return num;
  }
};

export const arraySlicer = <T>(array: T[], count: number): T[] => {
  return count <= array.length ? array.slice(0, count) : array;
};

export function filterProducts<K extends keyof GQL.PayformProductResponseDto>(array: GQL.PayformProductResponseDto[], key: K) {
  return array.filter(el => !el[key]);
}
export const chunkArray = (array: Array<any>, size: number) =>
  array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

export const replaceStrapiComponentName = (name: string) => {
  const re = /^[^.]+.\s*/;
  return name.replace(re, '');
};
