import { AnyAction } from 'redux';
import { SitePageContextProject } from '../../graphql-types';

export enum ActionTypes {
  HANDLE_SEARCH = 'search/HANDLE_SEARCH',
  FILTER_PROJECTS = 'search/FILTER_PROJECTS',
}

export interface HandleSearchAction extends AnyAction {
  type: ActionTypes.HANDLE_SEARCH;
  payload: string;
}

export interface FilterProjectsAction extends AnyAction {
  type: ActionTypes.FILTER_PROJECTS;
  payload: SitePageContextProject[];
}

export const handleSearchActionCreator = (value: string): HandleSearchAction => ({
  type: ActionTypes.HANDLE_SEARCH,
  payload: value,
});

export const filterProjectsActionCreator = (data: SitePageContextProject[]): FilterProjectsAction => ({
  type: ActionTypes.FILTER_PROJECTS,
  payload: data,
});

export type Action = HandleSearchAction | FilterProjectsAction;
