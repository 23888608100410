import { AnyAction } from 'redux';
import { DiscountResponseDto } from '@altermeliora/payform-types';
// import GQL from '../../graphql-types';
import { Order, OrderStatus, OrderKeyParams, PayformOrder, PayformOrderResponse, InvoiceCreateOrder } from '../../lib/common-interfaces';

// Action names

export enum ActionTypes {
  SET_ORDER_PROCESS_STATUS = 'order/SET_ORDER_PROCESS_STATUS',
  CREATE_ORDER = 'order/CREATE_ORDER',
  CHANGE_ORDER_STATUS = 'order/CHAGE_ORDER_STATUS',
  SUBMIT_ORDER = 'order/SUBMIT_ORDER',
  CHANGE_ORDER_STATUS_SAGA = 'order/CHANGE_ORDER_STATUS_SAGA',
  CLEAR_ORDER = 'order/CLEAR_ORDER',
  SAVE_ORDER = 'order/SAVE_ORDER',
  READ_ORDER = 'order/READ_ORDER',
  DELETE_ORDER = 'order/DELETE_ORDER',
  SET_SAVED_ORDER = 'order/SET_SAVED_ORDER',
  CLEAR_SAVED_ORDER = 'order/CLEAR_SAVED_ORDER',
  SET_LINK = 'order/SET_LINK',
  SET_ORDER_DISCOUNT = 'order/SET_ORDER_DISCOUNT',
  GET_ORDER_STATUS = 'order/GET_ORDER_STATUS',
  SET_ORDER_STATUS = 'order/SET_ORDER_STATUS',
  CLEAR_ORDER_STATUS = 'order/CLEAR_ORDER_STATUS',
  SET_MANAGER = 'order/SET_MANAGER',
  SHOW_MANAGER = 'order/SHOW_MANAGER',
  SAVE_ORDER_FIELDS = 'order/SAVE_ORDER_FIELDS',
}

// Action types

export interface SetOrderProcessStatusAction extends AnyAction {
  type: ActionTypes.SET_ORDER_PROCESS_STATUS;
  payload: {
    loading: boolean;
    error?: string;
  };
}

export interface CreateOrderAction extends AnyAction {
  type: ActionTypes.CREATE_ORDER;
  payload: InvoiceCreateOrder;
}

export interface ChangeOrderStatusAction extends AnyAction {
  type: ActionTypes.CHANGE_ORDER_STATUS;
  payload: OrderStatus;
}

export interface SubmitOrderAction extends AnyAction {
  type: ActionTypes.SUBMIT_ORDER;
  payload: Order;
}

export interface ChangeOrderStatusSagaAction extends AnyAction {
  type: ActionTypes.CHANGE_ORDER_STATUS_SAGA;
  payload: {
    orderId?: string;
    status: OrderStatus;
  };
}

export interface ClearOrderAction extends AnyAction {
  type: ActionTypes.CLEAR_ORDER;
}

export interface SaveOrderAction extends AnyAction {
  type: ActionTypes.SAVE_ORDER;
  payload: Partial<Order>;
}

export interface ReadOrderAction extends AnyAction {
  type: ActionTypes.READ_ORDER;
  payload: OrderKeyParams;
}

export interface DeleteOrderAction extends AnyAction {
  type: ActionTypes.DELETE_ORDER;
  payload: OrderKeyParams;
}

export interface SetSavedOrderAction extends AnyAction {
  type: ActionTypes.SET_SAVED_ORDER;
  payload: PayformOrder;
}

export interface ClearSavedOrderAction extends AnyAction {
  type: ActionTypes.CLEAR_SAVED_ORDER;
}

export interface SetLinkAction extends AnyAction {
  type: ActionTypes.SET_LINK;
  payload: string;
}

export interface SetOrderDiscountAction extends AnyAction {
  type: ActionTypes.SET_ORDER_DISCOUNT;
  payload: DiscountResponseDto;
}

export interface GetOrderStatusAction extends AnyAction {
  type: ActionTypes.GET_ORDER_STATUS;
  payload: string;
}

export interface SetOrderStatusAction extends AnyAction {
  type: ActionTypes.SET_ORDER_STATUS;
  payload: PayformOrderResponse;
}

export interface ClearOrderStatusAction extends AnyAction {
  type: ActionTypes.CLEAR_ORDER_STATUS;
}

export interface SetManagerAction extends AnyAction {
  type: ActionTypes.SET_MANAGER;
  payload: string;
}

export interface ShowManagerAction extends AnyAction {
  type: ActionTypes.SHOW_MANAGER;
  payload: boolean;
}

export interface SaveOrderFieldsAction extends AnyAction {
  type: ActionTypes.SAVE_ORDER_FIELDS;
  payload: Partial<Order>;
}

// Action creators

export const SetOrderProcessStatusActionCreator = (payload: { loading: boolean; error?: string }): SetOrderProcessStatusAction => {
  return {
    payload,
    type: ActionTypes.SET_ORDER_PROCESS_STATUS,
  };
};

export const CreateOrderActionCreator = (payload: InvoiceCreateOrder): CreateOrderAction => {
  return {
    payload,
    type: ActionTypes.CREATE_ORDER,
  };
};

export const ChangeOrderStatusActionCreator = (payload: OrderStatus): ChangeOrderStatusAction => {
  return {
    payload,
    type: ActionTypes.CHANGE_ORDER_STATUS,
  };
};

export const SubmitOrderActionCreator = (payload: Order): SubmitOrderAction => {
  return {
    payload,
    type: ActionTypes.SUBMIT_ORDER,
  };
};

export const ChangeOrderStatusSagaActionCreator = (payload: { orderId: string; status: OrderStatus }): ChangeOrderStatusSagaAction => {
  return {
    payload,
    type: ActionTypes.CHANGE_ORDER_STATUS_SAGA,
  };
};

export const ClearOrderActionCreator = (): ClearOrderAction => {
  return {
    type: ActionTypes.CLEAR_ORDER,
  };
};

export const SaveOrderActionCreator = (payload: Partial<Order>): SaveOrderAction => {
  return {
    payload,
    type: ActionTypes.SAVE_ORDER,
  };
};

export const ReadOrderActionCreator = (payload: OrderKeyParams): ReadOrderAction => {
  return {
    payload,
    type: ActionTypes.READ_ORDER,
  };
};

export const DeleteOrderActionCreator = (payload: OrderKeyParams): DeleteOrderAction => {
  return {
    payload,
    type: ActionTypes.DELETE_ORDER,
  };
};

export const SetSavedOrderActionCreator = (payload: PayformOrder): SetSavedOrderAction => {
  return {
    payload,
    type: ActionTypes.SET_SAVED_ORDER,
  };
};

export const ClearSavedOrderActionCreator = (): ClearSavedOrderAction => {
  return {
    type: ActionTypes.CLEAR_SAVED_ORDER,
  };
};

export const SetLinkActionCreator = (payload: string): SetLinkAction => {
  return {
    type: ActionTypes.SET_LINK,
    payload,
  };
};

export const GetOrderStatusActionCreator = (payload: string): GetOrderStatusAction => {
  return {
    type: ActionTypes.GET_ORDER_STATUS,
    payload,
  };
};

export const SetOrderStatusActionCreator = (payload: PayformOrderResponse): SetOrderStatusAction => {
  return {
    type: ActionTypes.SET_ORDER_STATUS,
    payload,
  };
};

export const ClearOrderStatusActionCreator = (): ClearOrderStatusAction => {
  return {
    type: ActionTypes.CLEAR_ORDER_STATUS,
  };
};

export const SetManagerActionCreator = (payload: string): SetManagerAction => {
  return {
    type: ActionTypes.SET_MANAGER,
    payload,
  };
};

export const ShowManagerActionCreator = (payload: boolean): ShowManagerAction => {
  return {
    type: ActionTypes.SHOW_MANAGER,
    payload,
  };
};

export const SaveOrderFieldsActionCreator = (payload: Partial<Order>): SaveOrderFieldsAction => {
  return {
    type: ActionTypes.SAVE_ORDER_FIELDS,
    payload,
  };
};

export type Action =
  | SetOrderProcessStatusAction
  | CreateOrderAction
  | ChangeOrderStatusAction
  | SubmitOrderAction
  | ChangeOrderStatusSagaAction
  | ClearOrderAction
  | SaveOrderAction
  | ReadOrderAction
  | DeleteOrderAction
  | SetSavedOrderAction
  | ClearSavedOrderAction
  | SetLinkAction
  | SetOrderDiscountAction
  | GetOrderStatusAction
  | SetOrderStatusAction
  | ClearOrderStatusAction
  | SetManagerAction
  | ShowManagerAction
  | SaveOrderFieldsAction;
