import { AnyAction } from 'redux';
import { UserAuth0 } from '../../lib/common-interfaces';

export enum ActionTypes {
  SET_LOGIN = 'login/SET_LOGIN',
  SET_LOGOUT = 'login/SET_LOGOUT',
}

export interface setLoginAction extends AnyAction {
  type: ActionTypes.SET_LOGIN;
  payload: UserAuth0;
}

export interface setLogoutAction extends AnyAction {
  type: ActionTypes.SET_LOGOUT;
}

export const setLoginActionCreator = (user: UserAuth0): setLoginAction => ({
  type: ActionTypes.SET_LOGIN,
  payload: user,
});

export const setLogoutActionCreator = (): setLogoutAction => ({
  type: ActionTypes.SET_LOGOUT,
});

export type Actions = setLoginAction | setLogoutAction;
