import { Action, ActionTypes } from './search.actions';
import { SitePageContextProject } from '../../graphql-types';

export interface SearchModel {
  value: string;
  filteredProjects: SitePageContextProject[] | null;
}

export const searchDefaultState: SearchModel = {
  value: '',
  filteredProjects: null,
};

export const reducer = (state = searchDefaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.HANDLE_SEARCH:
      return { ...state, value: action.payload };
    case ActionTypes.FILTER_PROJECTS:
      return { ...state, filteredProjects: action.payload };
    default:
      return state;
  }
};
