import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import * as shippingActions from './shipping.actions';
import i18n from '../../i18n';
import { ShippingMethodsRequestItem, getShippingMethodsRequest } from '../../api/shipping';
import { fakeShipping } from '../../lib/constants/data';

export function* getShippingMethodsSaga(action: shippingActions.GetShippingMethodsAction) {
  yield put(shippingActions.setShippingStatusActionCreator({ loading: true }));
  const APIshipping: ShippingMethodsRequestItem[] = action.payload.items.map(item => {
    return {
      id: item.product.contentful_id as string,
      quantity: item.quantity as number,
      country: action.payload.country as string,
    };
  });
  const response = yield call(getShippingMethodsRequest, APIshipping);
  if (response) {
    yield put(shippingActions.setShippingMethodsActionCreator(response));
  } else {
    const notificationOptions: ArgsProps = {
      placement: 'topRight',
      message: i18n.t('shell:order.messages.error'),
    };
    yield call(notification.error, notificationOptions);
  }
  yield put(shippingActions.setShippingStatusActionCreator({ loading: false }));
}

export default [takeLatest(shippingActions.ActionTypes.GET_SHIPPING_METHODS, getShippingMethodsSaga)];
