import { call, put, takeLatest } from 'redux-saga/effects';
import { notificationSagas } from '../../lib/utils/notification';
import * as strapiActions from './strapi.actions';
import i18n from '../../i18n';
import * as api from '../../api/strapi';

export function* getStrapiMainPagesSaga() {
  yield put(strapiActions.setProcessStatusActionCreator({ loading: true }));
  const response = yield call(api.getAllMainPagesRequest);
  if (response) {
    yield put(strapiActions.setMainPagesActionCreator(response));
  } else {
    yield call(notificationSagas, i18n.t('shell:order.messages.error'));
  }
  yield put(strapiActions.setProcessStatusActionCreator({ loading: false }));
}

export function* getStrapiProjectPagesSaga() {
  yield put(strapiActions.setProcessStatusActionCreator({ loading: true }));
  const response = yield call(api.getAllProjectPagesRequest);
  if (response) {
    yield put(strapiActions.setProjectPagesActionCreator(response));
  } else {
    yield call(notificationSagas, i18n.t('shell:order.messages.error'));
  }
  yield put(strapiActions.setProcessStatusActionCreator({ loading: false }));
}

export function* getStrapiBlogCategoriesSaga() {
  yield put(strapiActions.setProcessStatusActionCreator({ loading: true }));
  const response = yield call(api.getBlogCategories);
  if (response) {
    yield put(strapiActions.setBlogCategoriesActionCreator(response));
  } else {
    yield call(notificationSagas, i18n.t('shell:order.messages.error'));
  }
  yield put(strapiActions.setProcessStatusActionCreator({ loading: false }));
}

export function* getStrapiPoliticsSaga() {
  yield put(strapiActions.setProcessStatusActionCreator({ loading: true }));
  const response = yield call(api.getPoliticsRequest);
  if (response) {
    yield put(strapiActions.setPoliticsActionCreator(response));
  } else {
    yield call(notificationSagas, i18n.t('shell:order.messages.error'));
  }
  yield put(strapiActions.setProcessStatusActionCreator({ loading: false }));
}

export function* getStrapiProductsSaga() {
  yield put(strapiActions.setProcessStatusActionCreator({ loading: true }));
  const response = yield call(api.getAllProducts);
  if (response) {
    yield put(strapiActions.setProductsActionCreator(response));
  } else {
    yield call(notificationSagas, i18n.t('shell:order.messages.error'));
  }
  yield put(strapiActions.setProcessStatusActionCreator({ loading: false }));
}

export function* getStrapiArticlesSaga() {
  yield put(strapiActions.setProcessStatusActionCreator({ loading: true }));
  const response = yield call(api.getAllArticles);
  if (response) {
    yield put(strapiActions.setArticlesActionCreator(response));
  } else {
    yield call(notificationSagas, i18n.t('shell:order.messages.error'));
  }
  yield put(strapiActions.setProcessStatusActionCreator({ loading: false }));
}

export function* getStrapiFeedbackSaga() {
  yield put(strapiActions.setProcessStatusActionCreator({ loading: true }));
  const response = yield call(api.getAllFeedback);
  if (response) {
    yield put(strapiActions.setFeedbackActionCreator(response));
  } else {
    yield call(notificationSagas, i18n.t('shell:order.messages.error'));
  }
  yield put(strapiActions.setProcessStatusActionCreator({ loading: false }));
}

export default [
  takeLatest(strapiActions.ActionTypes.GET_MAIN_PAGES, getStrapiMainPagesSaga),
  takeLatest(strapiActions.ActionTypes.GET_PROJECT_PAGES, getStrapiProjectPagesSaga),
  takeLatest(strapiActions.ActionTypes.GET_BLOG_CATEGORIES, getStrapiBlogCategoriesSaga),
  takeLatest(strapiActions.ActionTypes.GET_POLITICS, getStrapiPoliticsSaga),
  takeLatest(strapiActions.ActionTypes.GET_PRODUCTS, getStrapiProductsSaga),
  takeLatest(strapiActions.ActionTypes.GET_ARTICLES, getStrapiArticlesSaga),
  takeLatest(strapiActions.ActionTypes.GET_FEEDBACK, getStrapiFeedbackSaga),
];
