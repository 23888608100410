import { PaymentMethod } from '../common-interfaces';

export const payOnline: PaymentMethod = {
  _id: 'payonline',
  name: 'payonline',
  icon: 'credit-card',
};

export enum ShippingTypes {
  TYPE_BASIC = 'basic',
  TYPE_FAST = 'fast',
  TYPE_EXPRESS = 'express',
  TYPE_SUPER_EXPRESS = 'super_express',
}

export enum PayformCurrency {
  Dollar = 'dollar',
  Euro = 'euro',
  Ruble = 'ruble',
  Shekel = 'shekel',
}

export enum NotificationPosition {
  topRight = 'topRight',
  topLeft = 'topLeft',
  bottomRight = 'bottomRight',
  bottomLeft = 'bottomLeft',
}

export enum CookieNotification {
  key = 'Cookie notification',
}
