import { notification } from 'antd';
import { ArgsProps, NotificationPlacement } from 'antd/lib/notification';

export const notificationSagas = (message: string, placement?: NotificationPlacement) => {
  const notificationOptions: ArgsProps = {
    placement: placement || 'topRight',
    message,
  };
  notification.error(notificationOptions);
};
