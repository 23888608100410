import { AnyAction } from 'redux';
import { PayformCurrency } from '../../graphql-types';

export enum ActionTypes {
  SET_CURRENCY = 'currency/SET_CURRENCY',
}

export interface SetCurrencyAction extends AnyAction {
  type: ActionTypes.SET_CURRENCY;
  payload: PayformCurrency;
}

export const setCurrencyActionCreator = (payload: PayformCurrency) => {
  return {
    type: ActionTypes.SET_CURRENCY,
    payload,
  };
};

export type Action = SetCurrencyAction;
