/* eslint-disable @typescript-eslint/camelcase */
import _ from 'lodash';
import { transliterate as tr } from 'transliteration';
import { Currency } from '@altermeliora/payform-types';
import {
  CartItem,
  Order,
  ColorOption,
  Geocode,
  ItemDiscount,
  PayformProduct,
  OrderWithOptions,
  ISelectedProject,
} from '../../lib/common-interfaces';
import { toDecimals } from '../../lib/utils';
import i18n from '../../i18n';
import { PayformOptionCategoryResponseDto, LanguageType } from '../../graphql-types';

export const addToCart = (cart: CartItem[], payload: CartItem): CartItem[] => {
  if (cart.length > 0) {
    const alreadyInCart = cart.findIndex(element => {
      if (
        (element.product.id === payload.product.id &&
          payload.selectedOptionIds &&
          element.selectedOptionIds &&
          _.isEqual(Object.values(payload.selectedOptionIds), Object.values(element.selectedOptionIds))) ||
        (element.product.id === payload.product.id && element.project && payload.project && element.project.id === payload.project.id) ||
        (element.product.id === payload.product.id &&
          payload.colorOption &&
          element.colorOption &&
          payload.colorOption.contentful_id === element.colorOption.contentful_id)
      ) {
        return true;
      }
    });
    if (alreadyInCart !== -1 && payload.quantity) {
      cart[alreadyInCart].quantity += payload.quantity;
      cart[alreadyInCart].discount = undefined;
    } else {
      cart.push(payload);
    }
  } else {
    cart.push(payload);
  }
  return cart;
};

export const removeFromCart = (
  cart: CartItem[],
  payload: {
    productId: number;
    quantity?: number;
    option?: PayformOptionCategoryResponseDto[];
    colorOption?: ColorOption;
    project?: ISelectedProject;
    showNotification?: boolean;
    selectedOptionIds?: Record<number, number>;
  }
): CartItem[] => {
  if (cart.length > 0) {
    const alreadyInCart = cart.findIndex((element, index) => {
      if (
        +element.product.id === payload.productId ||
        (payload.selectedOptionIds &&
          element.selectedOptionIds &&
          _.isEqual(Object.values(payload.selectedOptionIds), Object.values(element.selectedOptionIds))) ||
        (element.project && payload.project && element.project.id === payload.project.id) ||
        (payload.colorOption && element.colorOption && payload.colorOption.contentful_id === element.colorOption.contentful_id)
      ) {
        return index === 0 ? true : index;
      }
    });
    if (alreadyInCart !== -1 && payload.quantity && cart[alreadyInCart].quantity > payload.quantity) {
      cart[alreadyInCart].quantity = cart[alreadyInCart].quantity - payload.quantity;
      cart[alreadyInCart].discount = undefined;
    } else if (alreadyInCart !== -1 && (!payload.quantity || cart[alreadyInCart].quantity <= payload.quantity)) {
      cart.splice(alreadyInCart, 1);
    }
  }
  return cart;
};

export const applyDiscounts = (cart: CartItem[], payload: ItemDiscount[]): CartItem[] => {
  if (cart.length > 0 && payload.length > 0) {
    payload.forEach(discountItem => {
      discountItem.products.forEach(discountProduct => {
        cart.forEach((cartItem, index, cartArray) => {
          // console.log('discount applies', cartItem.product.id, discountProduct.id);
          if (cartItem.product.id === discountProduct.id) {
            cartArray[index] = {
              ...cartItem,
              discount: discountItem,
            };
          } else {
            cartArray[index] = cartItem;
          }
        });
      });
    });
  }
  // console.log('cart', cart);
  return cart;
};

export const convertOrderWithOptions = (order: Partial<Order>): OrderWithOptions | Partial<OrderWithOptions> | undefined => {
  if (order.user && order.products) {
    return {
      user: {
        first_name: order.user.firstName as string,
        last_name: order.user.lastName as string,
        email: order.user.email as string,
        country: order.user.countryCode as string,
        city: order.user.city as string,
        address: order.user.address as string,
        zip: order.user.postalCode as string,
        phone: order.user.phone as string,
      },
      currency: Currency.euro,
      products: order.products.map(product => {
        return {
          id: product.product.id as string,
          name: product.product.title as string,
          price: product.product.price as number,
          quantity: product.quantity as number,
          option: product.option,
          colorOption: product.colorOption,
          discount: product.discount,
        };
      }) as PayformProduct[],
      shipment_info: order.shippingMethod && {
        type: order.shippingMethod.type,
        method: order.shippingMethod.method,
        price_currency: order.shippingMethod.price_currency,
        total_price: order.shippingMethod.total_price,
      },
      // discount: _.compact(
      //   order.products.map(product => {
      //     if (product.discount) {
      //       return {
      //         id: product.discount.id,
      //         title: product.discount.title,
      //         total: itemDiscountTotal(product, currency),
      //         currency: Currency.euro,
      //       };
      //     }
      //   })
      // ),
      manager: order.manager,
    };
  }
  return undefined;
};

export const convertOrderForPayment = (order: Order): OrderWithOptions | undefined => {
  if (order.user && order.products && order.shippingMethod) {
    return {
      user: {
        first_name: tr(order.user.firstName as string),
        last_name: tr(order.user.lastName as string),
        email: order.user.email as string,
        country: order.user.countryCode as string,
        city: tr(order.user.city as string),
        address: tr(order.user.address as string),
        zip: order.user.postalCode as string,
        phone: order.user.phone as string,
      },
      currency: Currency.euro,
      products: order.products.map(product => {
        return {
          id: product.product.id as string,
          name: product.product.name[i18n.language as LanguageType] as string,
          price: product.product.prices[0].list[0].amount as number,
          quantity: product.quantity as number,
          option: product.option,
          colorOption: product.colorOption,
        };
      }) as PayformProduct[],
      shipment_info: {
        type: order.shippingMethod.type,
        method: order.shippingMethod.method,
        price_currency: order.shippingMethod.price_currency,
        total_price: toDecimals(order.shippingMethod.total_price as number),
      },
      // discount: _.compact(
      //   order.products.map(product => {
      //     if (product.discount && product.discount.title && product.discount.id) {
      //       return {
      //         id: product.discount.id,
      //         title: product.discount.title,
      //         total: toDecimals(itemDiscountTotal(product)),
      //         currency: Currency.euro,
      //       };
      //     }
      //   })
      // ),
      manager: order.manager,
    };
  }
  return undefined;
};

export const extractCountry = (geolocation: Geocode): string => {
  const addressComponent = geolocation.results.find(address => {
    if (address.types.includes('country')) return true;
    return false;
  });
  if (addressComponent) {
    return addressComponent.address_components.find(address => {
      if (address.types.includes('country')) return true;
    }).short_name;
  }
  if (!addressComponent) {
    const naturalFeature = geolocation.results.find(address => {
      if (address.types.includes('natural_feature')) return true;
      return false;
    });
    if (
      naturalFeature &&
      naturalFeature.address_components.find(address => {
        if (address.long_name === 'Crimean Peninsula') return true;
      })
    ) {
      return 'RU';
    }
  }
};
