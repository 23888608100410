import { AnyAction } from 'redux';
import { CartItem, ShippingMethod } from '../../lib/common-interfaces';

// Action names

export enum ActionTypes {
  SET_SHIPPING_STATUS = 'shipping/SET_SHIPPING_STATUS',
  GET_SHIPPING_METHODS = 'shipping/GET_SHIPPING_METHODS',
  SET_SHIPPING_METHODS = 'shipping/SET_SHIPPING_METHODS',
  SET_SELECTED_SHIPPING = 'shipping/SET_SELECTED_SHIPPING',
  UPDATE_SHIPPING = 'shipping/UPDATE_SHIPPING',
}

// Action types

export interface SetShippingStatusAction extends AnyAction {
  type: ActionTypes.SET_SHIPPING_STATUS;
  payload: {
    loading: boolean;
    error?: string;
  };
}

export interface UpdateShippingAction extends AnyAction {
  type: ActionTypes.UPDATE_SHIPPING;
  payload: boolean;
}

export interface GetShippingMethodsAction extends AnyAction {
  type: ActionTypes.GET_SHIPPING_METHODS;
  payload: { items: CartItem[]; country: string };
}

export interface SetShippingMethodsAction extends AnyAction {
  type: ActionTypes.SET_SHIPPING_METHODS;
  payload: ShippingMethod[];
}

export interface SetSelectedShippingAction extends AnyAction {
  type: ActionTypes.SET_SELECTED_SHIPPING;
  payload: ShippingMethod;
}

// Action creators

export const updateShippingActionCreator = (payload: boolean): UpdateShippingAction => {
  return {
    payload,
    type: ActionTypes.UPDATE_SHIPPING,
  };
};

export const setShippingStatusActionCreator = (payload: { loading: boolean; error?: string }): SetShippingStatusAction => {
  return {
    payload,
    type: ActionTypes.SET_SHIPPING_STATUS,
  };
};

export const getShippingMethodsActionCreator = (payload: { items: CartItem[]; country: string }): GetShippingMethodsAction => {
  return {
    payload,
    type: ActionTypes.GET_SHIPPING_METHODS,
  };
};

export const setShippingMethodsActionCreator = (payload: ShippingMethod[]): SetShippingMethodsAction => {
  return {
    payload,
    type: ActionTypes.SET_SHIPPING_METHODS,
  };
};

export const setSelectedShippingActionCreator = (payload: ShippingMethod): SetSelectedShippingAction => {
  return {
    payload,
    type: ActionTypes.SET_SELECTED_SHIPPING,
  };
};

export type Action =
  | SetShippingStatusAction
  | GetShippingMethodsAction
  | SetShippingMethodsAction
  | SetSelectedShippingAction
  | UpdateShippingAction;
