import { APIRequest } from './api-service/api-request';

export const getAllMainPagesRequest = () => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/pages`);
};

export const getPageRequest = (id: number) => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/pages/${id}`);
};

export const getAllProjectPagesRequest = () => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/projects`);
};

export const getPoliticsRequest = () => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/politics`);
};

export const getBlogCategories = () => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/blog-categories`);
};

export const getAllArticles = () => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/articles`);
};

export const getAllProducts = () => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/products`);
};

export const getAllFeedback = () => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/feedbacks`);
};

export const getDeliveries = () => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/deliveries`);
};

export const getVideoDataById = (id: number) => {
  const request = new APIRequest();
  return request.get(`${process.env.STRAPI_API_URL}/videos/${id}`);
};
