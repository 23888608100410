import { AnyAction } from 'redux';
import {
  StrapiPages,
  SitePageContextProject,
  StrapiArticlesBlogCategory,
  StrapiPolitics,
  StrapiProducts,
  StrapiArticles,
  StrapiFeedback,
} from '../../graphql-types';

export enum ActionTypes {
  GET_MAIN_PAGES = 'strapi/GET_MAIN_PAGES',
  SET_MAIN_PAGES = 'strapi/SET_MAIN_PAGES',
  SET_STRAPI_PROCESS_STATUS = 'strapi/SET_STRAPI_PROCESS_STATUS',
  GET_PROJECT_PAGES = 'strapi/GET_PROJECT_PAGES',
  SET_PROJECT_PAGES = 'strapi/SET_PROJECT_PAGES',
  GET_BLOG_CATEGORIES = 'strapi/GET_BLOG_CATEGORIES',
  SET_BLOG_CATEGORIES = 'strapi/SET_BLOG_CATEGORIES',
  GET_POLITICS = 'strapi/GET_POLITICS',
  SET_POLITICS = 'strapi/SET_POLITICS',
  GET_PRODUCTS = 'strapi/GET_PRODUCTS',
  SET_PRODUCTS = 'strapi/SET_PRODUCTS',
  GET_ARTICLES = 'strapi/GET_ARTICLES',
  SET_ARTICLES = 'strapi/SET_ARTICLES',
  GET_FEEDBACK = 'strapi/GET_FEEDBACK',
  SET_FEEDBACK = 'strapi/SET_FEEDBACK',
}

export interface GetMainPagesAction extends AnyAction {
  type: ActionTypes.GET_MAIN_PAGES;
}

export interface SetMainPagesAction extends AnyAction {
  type: ActionTypes.SET_MAIN_PAGES;
  payload: StrapiPages[];
}

export interface SetProcessStatusAction extends AnyAction {
  type: ActionTypes.SET_STRAPI_PROCESS_STATUS;
  payload: {
    loading: boolean;
    error?: string;
  };
}

export interface GetProjectPagesAction extends AnyAction {
  type: ActionTypes.GET_PROJECT_PAGES;
}

export interface SetProjectPagesAction extends AnyAction {
  type: ActionTypes.SET_PROJECT_PAGES;
  payload: SitePageContextProject[];
}

export interface GetBlogCategoriesAction extends AnyAction {
  type: ActionTypes.GET_BLOG_CATEGORIES;
}

export interface SetBlogCategoriesAction extends AnyAction {
  type: ActionTypes.SET_BLOG_CATEGORIES;
  payload: StrapiArticlesBlogCategory[];
}

export interface GetPoliticsAction extends AnyAction {
  type: ActionTypes.GET_POLITICS;
}

export interface SetPoliticsAction extends AnyAction {
  type: ActionTypes.SET_POLITICS;
  payload: StrapiPolitics[];
}

export interface GetProductsAction extends AnyAction {
  type: ActionTypes.GET_PRODUCTS;
}
export interface SetProductsAction extends AnyAction {
  type: ActionTypes.SET_PRODUCTS;
  payload: StrapiProducts[];
}

export interface GetArticlesAction extends AnyAction {
  type: ActionTypes.GET_ARTICLES;
}
export interface SetArticlesAction extends AnyAction {
  type: ActionTypes.SET_ARTICLES;
  payload: StrapiArticles[];
}

export interface GetFeedbackAction extends AnyAction {
  type: ActionTypes.GET_FEEDBACK;
}
export interface SetFeedbackAction extends AnyAction {
  type: ActionTypes.SET_FEEDBACK;
  payload: StrapiFeedback[];
}

export const getMainPagesActionCreator = (): GetMainPagesAction => ({
  type: ActionTypes.GET_MAIN_PAGES,
});

export const setMainPagesActionCreator = (data: StrapiPages[]): SetMainPagesAction => ({
  type: ActionTypes.SET_MAIN_PAGES,
  payload: data,
});

export const setProcessStatusActionCreator = (payload: { loading: boolean; error?: string }): SetProcessStatusAction => ({
  type: ActionTypes.SET_STRAPI_PROCESS_STATUS,
  payload,
});

export const getProjectPagesActionCreator = (): GetProjectPagesAction => ({
  type: ActionTypes.GET_PROJECT_PAGES,
});

export const setProjectPagesActionCreator = (data: SitePageContextProject[]): SetProjectPagesAction => ({
  type: ActionTypes.SET_PROJECT_PAGES,
  payload: data,
});

export const getBlogCategoriesActionCreator = () => ({
  type: ActionTypes.GET_BLOG_CATEGORIES,
});

export const setBlogCategoriesActionCreator = (payload: StrapiArticlesBlogCategory[]) => ({
  type: ActionTypes.SET_BLOG_CATEGORIES,
  payload,
});

export const getPoliticsActionCreator = () => ({
  type: ActionTypes.GET_POLITICS,
});

export const setPoliticsActionCreator = (payload: StrapiPolitics[]) => ({
  type: ActionTypes.SET_POLITICS,
  payload,
});

export const getProductsActionCreator = () => ({
  type: ActionTypes.GET_PRODUCTS,
});

export const setProductsActionCreator = (payload: StrapiProducts[]) => ({
  type: ActionTypes.SET_PRODUCTS,
  payload,
});

export const geArticlestActionCreator = () => ({
  type: ActionTypes.GET_ARTICLES,
});

export const setArticlesActionCreator = (payload: StrapiArticles[]) => ({
  type: ActionTypes.SET_ARTICLES,
  payload,
});

export const getFeedbacktActionCreator = () => ({
  type: ActionTypes.GET_FEEDBACK,
});

export const setFeedbackActionCreator = (payload: StrapiFeedback[]) => ({
  type: ActionTypes.SET_FEEDBACK,
  payload,
});

export type Action =
  | GetMainPagesAction
  | SetMainPagesAction
  | SetProcessStatusAction
  | GetProjectPagesAction
  | SetProjectPagesAction
  | GetBlogCategoriesAction
  | SetBlogCategoriesAction
  | GetPoliticsAction
  | SetPoliticsAction
  | GetProductsAction
  | SetProductsAction
  | GetArticlesAction
  | SetArticlesAction
  | GetFeedbackAction
  | SetFeedbackAction;
