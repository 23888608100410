import { Action, ActionTypes } from './order.actions';
import { Order, PayformOrderResponse, OrderWithOptions, InvoiceCreateOrder } from '../../lib/common-interfaces';

export interface OrderModel {
  status: {
    loading: boolean;
    error?: string;
  };
  order?: InvoiceCreateOrder;
  paymentUrl?: string;
  invoiceId?: string;
  key?: string;
  savedOrder?: OrderWithOptions;
  link?: string;
  checkStatus?: PayformOrderResponse;
  manager?: string;
  showManager?: boolean;
  savedFields?: Partial<Order>;
}

export const orderInitialState: OrderModel = {
  status: {
    loading: false,
  },
};

export const reducer = (state = orderInitialState, action: Action): OrderModel => {
  switch (action.type) {
    case ActionTypes.SET_ORDER_PROCESS_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload,
        },
      };
    case ActionTypes.CREATE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case ActionTypes.CHANGE_ORDER_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          status: action.payload,
        },
      };
    case ActionTypes.CLEAR_ORDER:
      return {
        ...state,
        order: undefined,
      };
    case ActionTypes.SET_SAVED_ORDER:
      return {
        ...state,
        savedOrder: action.payload,
      };
    case ActionTypes.CLEAR_SAVED_ORDER:
      return {
        ...state,
        savedOrder: undefined,
      };
    case ActionTypes.SET_LINK:
      return {
        ...state,
        link: action.payload,
      };
    case ActionTypes.SET_ORDER_STATUS:
      return {
        ...state,
        checkStatus: action.payload,
      };
    case ActionTypes.CLEAR_ORDER_STATUS:
      return {
        ...state,
        checkStatus: undefined,
      };
    case ActionTypes.SET_MANAGER:
      return {
        ...state,
        manager: action.payload,
      };
    case ActionTypes.SHOW_MANAGER:
      return {
        ...state,
        showManager: action.payload,
      };
    case ActionTypes.SAVE_ORDER_FIELDS:
      return {
        ...state,
        savedFields: action.payload,
      };
    default:
      return state;
  }
};
