import { Action, ActionTypes } from './currency.actions';
import { PayformCurrency as TPayformCurrency } from '../../graphql-types';
import { PayformCurrency } from '../../lib/constants';

export interface CurrencyModel {
  currency: TPayformCurrency;
}

export const currencyInitialState: CurrencyModel = {
  currency: PayformCurrency.Euro,
};

export const reducer = (state = currencyInitialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    default:
      return state;
  }
};
