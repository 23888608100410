// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-academy-of-nutrition-tsx": () => import("./../../../src/pages/academy-of-nutrition.tsx" /* webpackChunkName: "component---src-pages-academy-of-nutrition-tsx" */),
  "component---src-pages-academy-of-promotion-tsx": () => import("./../../../src/pages/academy-of-promotion.tsx" /* webpackChunkName: "component---src-pages-academy-of-promotion-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-face-fitness-tsx": () => import("./../../../src/pages/face-fitness.tsx" /* webpackChunkName: "component---src-pages-face-fitness-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-fitness-equipment-tsx": () => import("./../../../src/pages/fitness-equipment.tsx" /* webpackChunkName: "component---src-pages-fitness-equipment-tsx" */),
  "component---src-pages-health-and-nutrition-tsx": () => import("./../../../src/pages/health-and-nutrition.tsx" /* webpackChunkName: "component---src-pages-health-and-nutrition-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */),
  "component---src-pages-slimming-school-tsx": () => import("./../../../src/pages/slimming-school.tsx" /* webpackChunkName: "component---src-pages-slimming-school-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-training-complex-tsx": () => import("./../../../src/pages/training-complex.tsx" /* webpackChunkName: "component---src-pages-training-complex-tsx" */),
  "component---src-pages-user-profile-tsx": () => import("./../../../src/pages/user-profile.tsx" /* webpackChunkName: "component---src-pages-user-profile-tsx" */),
  "component---src-pages-warranties-tsx": () => import("./../../../src/pages/warranties.tsx" /* webpackChunkName: "component---src-pages-warranties-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog_template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-feedback-template-tsx": () => import("./../../../src/templates/feedback_template.tsx" /* webpackChunkName: "component---src-templates-feedback-template-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/product_page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/project_page.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */),
  "component---src-templates-taplink-page-tsx": () => import("./../../../src/templates/taplink_page.tsx" /* webpackChunkName: "component---src-templates-taplink-page-tsx" */),
  "component---src-templates-videos-template-tsx": () => import("./../../../src/templates/videos_template.tsx" /* webpackChunkName: "component---src-templates-videos-template-tsx" */)
}

