import ApolloClient, { gql } from 'apollo-boost';
import fetch from 'isomorphic-fetch';

export const client = new ApolloClient({
  uri: process.env.PAYFORM_GRAPHQL_URL,
  fetch,
  typeDefs: gql`
    enum ProductType {
      material
      project
      media
      masterclass
    }
  `,
});
