import { Action, ActionTypes } from './shipping.actions';
import { ShippingMethod } from '../../lib/common-interfaces';

export interface ShippingModel {
  status: {
    loading: boolean;
    error?: string;
  };
  methods?: ShippingMethod[];
  selected?: ShippingMethod;
  updateStatus: boolean;
}

export const shippingInitialState: ShippingModel = {
  status: {
    loading: false,
  },
  updateStatus: false,
};

export const reducer = (state = shippingInitialState, action: Action): ShippingModel => {
  switch (action.type) {
    case ActionTypes.UPDATE_SHIPPING:
      return {
        ...state,
        updateStatus: action.payload,
      };
    case ActionTypes.SET_SHIPPING_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload,
        },
      };
    case ActionTypes.SET_SHIPPING_METHODS:
      return {
        ...state,
        methods: action.payload,
      };
    case ActionTypes.SET_SELECTED_SHIPPING:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};
