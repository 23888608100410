import { Action, ActionTypes } from './strapi.actions';
import {
  StrapiPages,
  SitePageContextProject,
  StrapiArticlesBlogCategory,
  StrapiPolitics,
  StrapiProducts,
  StrapiArticles,
  StrapiFeedback,
} from '../../graphql-types';

export interface StrapiModel {
  pages: StrapiPages[] | undefined;
  projects: SitePageContextProject[] | undefined;
  status: {
    loading: boolean;
    error?: string;
  };
  blogCategories: StrapiArticlesBlogCategory[] | undefined;
  politics: StrapiPolitics[] | undefined;
  products: StrapiProducts[] | undefined;
  articles: StrapiArticles[] | undefined;
  feedback: StrapiFeedback[] | undefined;
}

export const strapiDefaultState: StrapiModel = {
  pages: undefined,
  projects: undefined,
  status: {
    loading: false,
  },
  blogCategories: undefined,
  politics: undefined,
  products: undefined,
  articles: undefined,
  feedback: undefined,
};

export const reducer = (state = strapiDefaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_MAIN_PAGES:
      return { ...state, pages: action.payload };
    case ActionTypes.SET_STRAPI_PROCESS_STATUS:
      return { ...state, status: action.payload };
    case ActionTypes.SET_PROJECT_PAGES:
      return { ...state, projects: action.payload };
    case ActionTypes.SET_BLOG_CATEGORIES:
      return { ...state, blogCategories: action.payload };
    case ActionTypes.SET_POLITICS:
      return { ...state, politics: action.payload };
    case ActionTypes.SET_PRODUCTS:
      return { ...state, products: action.payload };
    case ActionTypes.SET_ARTICLES:
      return { ...state, articles: action.payload };
    case ActionTypes.SET_FEEDBACK:
      return { ...state, feedback: action.payload };
    default:
      return state;
  }
};
