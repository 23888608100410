import { ActionTypes, Actions } from './actions';
import { UserAuth0 } from '../../lib/common-interfaces';

export interface AuthModel {
  user: null | UserAuth0;
}

const initialState: AuthModel = {
  user: null,
};

export const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.SET_LOGIN:
      return { ...state, user: action.payload };
    case ActionTypes.SET_LOGOUT:
      return { ...state, user: null };
    default:
      return state;
  }
};
