/* eslint-disable @typescript-eslint/no-var-requires */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const langs = require('../lib/constants/locales');
import shellEN from './translations/en/shell.json';
import shellRU from './translations/ru/shell.json';
import checkoutEN from './translations/en/checkout.json';
import checkoutRU from './translations/ru/checkout.json';
import statusEN from './translations/en/status.json';
import statusRU from './translations/ru/status.json';
import headerEN from './translations/en/header.json';
import headerRU from './translations/ru/header.json';
import footerEN from './translations/en/footer.json';
import footerRU from './translations/ru/footer.json';

const { languages, defaultLanguage } = langs;

const resources = {
  en: {
    shell: shellEN,
    checkout: checkoutEN,
    status: statusEN,
    header: headerEN,
    footer: footerEN,
  },
  ru: {
    shell: shellRU,
    checkout: checkoutRU,
    status: statusRU,
    header: headerRU,
    footer: footerRU,
  },
};

const initializeLang = (): string | undefined => {
  if (typeof window !== 'undefined') {
    const localLang = localStorage.getItem('lang');
    // console.log('[LANG_FROM_LOCAL_STORAGE]: ', localLang);
    // let lang = localLang || navigator.language.split('-')[0];
    let lang = localLang || defaultLanguage; // TODO should use here LangContext or common utils
    // console.log('[localLang || defaultLanguage]: ', lang);
    const isSupportedLang = languages.find((l: string) => lang === l);
    if (!isSupportedLang) lang = defaultLanguage;
    return lang;
  }
  return defaultLanguage;
};

const initLang = initializeLang();

console.log('[INIT_LANG]: ', initLang);

i18n.use(initReactI18next).init({
  resources,
  lng: initLang,
  fallbackLng: defaultLanguage,
});

export default i18n;
